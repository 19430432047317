import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
// import Image from "gatsby-image"
import TopMenu from "../components/topMenu"
// import {chunkifyArray} from "../utils/utils"
import Footer from "../components/footer"
import PhotoGallery from "../components/photoGallery"
import ReactMarkdown from "react-markdown"
// import frame from "../../content/assets/frame.svg"

const AlbumPageTemplate = (props) => {
    // console.log("AlbumPageTemplate: ", props);
    // const { previous, next } = props.pageContext;
    // const slug = props.data.mdx.slug;
    const fileNameRegExp = /\/([^/]+)\.\w{1,4}$/;
    const album = props.data.mdx;
    const slug = album.slug.split('.')[0];
    const cloudinaryImages = props.data.cloudinaryImages.nodes;

    let pictures = album.frontmatter.pictures ? album.frontmatter.pictures.map((pic, index) => {
      const res = fileNameRegExp.exec(pic.image);
      if (!res || !res[1]) return undefined;
      const picData = cloudinaryImages.filter(pic => pic.public_id.includes(res[1]))[0];
      return {
        index: index,
        type: picData.type || "image",
        id: picData.id,
        src: picData.imgUrl,
        thumbnail: picData.thumb,
        w: picData.width,
        h: picData.height,
        title: pic.title || "",
        description: picData.description || "",
      };
    }) : [];

    // console.warn(pictures);

    // pictures = [
    //   {
    //     type: "text",
    //     title: album.frontmatter.title,
    //     description: album.frontmatter.description,
    //   }, 
    //   ...pictures
    // ];
    // console.log(pictures);

    // const columns = chunkifyArray(pictures, 3, true);

    const getThumbnailContent = (item) => {
      return (
        <div className="">
          <img src={item.thumbnail} alt={item.title} loading="lazy" className="vp-album-item__pic mb1"/>
          <h5 className="mb0">{item.title}</h5>
        </div>
      );
    }

    return (
      <section className={"page vp-album vp-lang-" + props.pageContext.language}>
        <SEO
          title={album.frontmatter.title}
          description={album.frontmatter.description}
        />
        <TopMenu smallLogo={true} lang={props.pageContext.language} languages={props.pageContext.languages} translation={props.pageContext.translation} slug={slug}></TopMenu>
        <div className="container-1200">
          <div className="section">
          <div className="vp-album-header">
              <h3 className="vp-album-title color-accent-3">{album.frontmatter.title}</h3>
              <div className="vp-album-desc article">
                <ReactMarkdown source={album.frontmatter.description} linkTarget="_blank"/>
              </div>
          </div> 
          {
            Boolean(pictures.length) && <PhotoGallery items={pictures} options={{}} thumbnailContent={getThumbnailContent}/>
          } 
          </div>
        </div>
        <Footer lang={props.pageContext.language} languages={props.pageContext.languages} translation={props.pageContext.translation}></Footer>
      </section>
    )
  }

export default AlbumPageTemplate

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    mdx( id: { eq: $id } ) {
      id
      slug
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        description
        title
        pictures {
          image
          title
        }
      }
    }
    cloudinaryImages: allCloudinaryItem {
      nodes {
        id
        public_id
        thumb
        imgUrl
        width
        height
        orientation
      }
    }
  }
`
